<template>
  <Alert
    v-if="
      fulFillment.minCheckoutAmount &&
      fulFillment.minCheckoutAmount >
        fulFillment.totalAmount + orderSummary.tipAmount - additionalCharges
    "
    :show="true"
    :variant="fulFillment.fixedCharge ? 'warning' : 'danger'"
    :className="errorId"
    :id="errorId"
  >
    <!-- Please Add
    {{ (fulFillment.minCheckoutAmount - fulFillment.subTotal) | currency }}
    to qualify for {{ fulFillment.minCheckoutAmount | currency }}
    minimum
    <template v-if="fulFillment.fixedCharge && fulFillment.fixedCharge > 0">
      or you can give fee of
      {{ fulFillment.fixedCharge | currency }}
      and continue with proceed to checkout.
    </template> -->
    Please add
    {{
      (fulFillment.minCheckoutAmount -
        orderSummary.tipAmount -
        (fulFillment.totalAmount - additionalCharges))
        | currency
    }}
    to qualify for {{ fulFillment.minCheckoutAmount | currency }} minimum order
    amount.
    <template v-if="fulFillment.fixedCharge && fulFillment.fixedCharge > 0">
      You may also pay additional fee of
      {{ fulFillment.fixedCharge | currency }} to bypass this minimum and
      proceed directly to checkout.
    </template>
  </Alert>
</template>
<script>
export default {
  name: 'MinimumCheckoutError',
  props: {
    fulFillment: {
      type: Object,
      default: null,
    },
    errorId: {
      type: String,
      default: 'error',
    },
    orderSummary: {
      type: Object,
      default: null,
    },
  },
  computed: {
    additionalCharges() {
      let charges = 0
      if (this.fulFillment?.additionalCharges) {
        let chargeAmount = this.fulFillment.additionalCharges.find(
          (x) => x.addChargeType == 1
        )?.chargeAmount
        charges = chargeAmount ? this.fulFillment.fixedCharge : 0
      }
      return charges
    },
  },
}
</script>
