var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.fulFillment.minCheckoutAmount &&
    _vm.fulFillment.minCheckoutAmount >
      _vm.fulFillment.totalAmount + _vm.orderSummary.tipAmount - _vm.additionalCharges
  )?_c('Alert',{attrs:{"show":true,"variant":_vm.fulFillment.fixedCharge ? 'warning' : 'danger',"className":_vm.errorId,"id":_vm.errorId}},[_vm._v("\n  Please add\n  "+_vm._s(_vm._f("currency")((_vm.fulFillment.minCheckoutAmount -
      _vm.orderSummary.tipAmount -
      (_vm.fulFillment.totalAmount - _vm.additionalCharges))))+"\n  to qualify for "+_vm._s(_vm._f("currency")(_vm.fulFillment.minCheckoutAmount))+" minimum order\n  amount.\n  "),(_vm.fulFillment.fixedCharge && _vm.fulFillment.fixedCharge > 0)?[_vm._v("\n    You may also pay additional fee of\n    "+_vm._s(_vm._f("currency")(_vm.fulFillment.fixedCharge))+" to bypass this minimum and\n    proceed directly to checkout.\n  ")]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }